<template>
  <div class="text-center">
    <v-menu :offset-y="true">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ icon }}</v-icon>
        </div>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-title>
            <v-color-picker
              :value="value"
              elevation="15"
              @update:color="changeCardColor"
            />
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { debounce } from "lodash";
export default {
  props: {
    icon: {
      type: String,
      default() {
        return "mdi-brush-variant";
      },
    },
    value: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    changeCardColor(val) {
      this.$emit("input", val.hex);
      this.debounceChange(val.hex);
    },
    debounceChange: debounce(function (hex) {
      this.$emit("change", hex);
    }, 500),
  },
};
</script>
