import service from "../services/vehicle-types-service";

const state = {
  vehicleTypesList: [],
};

const mutations = {
  SET_VEHICLE_TYPES(state, vehicleTypes) {
    state.vehicleTypesList = vehicleTypes;
  },
};

const actions = {
  list: (store, params) => {
    return service.list(params).then((data) => {
      store.commit("SET_VEHICLE_TYPES", data.body.data);
    });
  },
};

const getters = {
  vehicleTypesList(state) {
    return state.vehicleTypesList;
  },
};

const vehicleTypes = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default vehicleTypes;
