import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios
    .get(`${base_url}journey-report-data`, { params })
    .then((response) => {
      return response.data;
    });
}
async function summary(params) {
  return axios
    .post(`${base_url}journey-report-aggregate-data`, params)
    .then((response) => {
      return response.data;
    });
}
async function exportList(params) {
  const URL = `export-journey-report-data/${params.type}`;
  const response = await axios.get(`${base_url}${URL}`, { params });
  return response.data;
}

async function exportSummary(params) {
  const URL = `export-journey-report-aggregate-data/${params.type}`;
  const response = await axios.get(`${base_url}${URL}`, { params });
  return response.data;
}

export default {
  list,
  summary,
  exportList,
  exportSummary,
};
