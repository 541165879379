import service from "@/store/services/md-makes-service";

const state = {
  isLoadingMdMakes: false,
  listMdMakes: [],
  listMdMakeByID: {},
  meta: {},
};

const mutations = {
  SET_MAKE(state, mdMake) {
    state.listMdMakes = mdMake;
  },
  SET_MAKE_BY_ID(state, mdMake) {
    state.listMdMakeByID = mdMake;
  },

  ADD_MAKE(state, mdMake) {
    state.listMdMakes.push(mdMake);
  },

  UPDATE_LIST(state, mdMake) {
    const item = state.listMdMakes.find((item) => item.id === mdMake.id);
    Object.assign(item, mdMake);
  },

  DELETE_SIM(state, mdMake) {
    state.listMdMakes.splice(
      state.listMdMakes.map((o) => o.id).indexOf(mdMake.id),
      1
    );
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingMdMakes = true;
    service.list(params).then((data) => {
      store.commit("SET_MAKE", data.body.data);
      store.commit("SET_META", data.body);
      store.state.isLoadingMdMakes = false;
    });
  },
  show: (store, params) => {
    store.state.isLoadingMdMakes = true;
    service.show(params).then((data) => {
      store.commit("SET_MAKE_BY_ID", data.body);
      store.state.isLoadingMdMakes = false;
    });
  },

  add: (store, { mdMake, filter }) => {
    return service.add(mdMake).then((data) => {
      store.commit("ADD_MAKE", data.body);
      store.dispatch("list", filter);
    });
  },

  update: (store, mdMake) => {
    return service.update(mdMake).then((mdMake) => {
      store.commit("UPDATE_LIST", mdMake);
    });
  },

  destroy: (store, mdMake) => {
    return service.destroy(mdMake).then(() => {
      store.commit("DELETE_SIM", mdMake);
    });
  },
};

const getters = {
  isLoadingMdMakes(state) {
    return state.isLoadingMdMakes;
  },

  listMdMakes(state) {
    return state.listMdMakes;
  },
  getMdMakeById(state) {
    return state.listMdMakeByID;
  },
  activelistMdMakes() {
    return state.listMdMakes.filter((mdMake) => mdMake.is_active == true);
  },

  meta(state) {
    return state.meta;
  },
};

const mdMake = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default mdMake;
