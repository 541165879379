import service from "../services/comparison-service";

const state = {
  isLoading: false,
  vehicleMeta: {},
  customerMeta: {},
  vehicleComparisonData: [],
  customerComparisonData: [],
};

const mutations = {
  SET_VEHICLE_COMPARISON(state, payload) {
    state.vehicleComparisonData = payload;
    return payload;
  },
  SET_CUSTOMER_COMPARISON(state, payload) {
    state.customerComparisonData = payload;
    return payload;
  },
  SET_VEHICLE_META(state, payload) {
    state.vehicleMeta = payload;
  },
  SET_CUSTOMER_META(state, payload) {
    state.customerMeta = payload;
  },
};

const actions = {
  vehicleComarpisonList: (store, params) => {
    store.state.isLoading = true;
    return service
      .vehicle_aggregated(params)
      .then((data) => {
        store.commit("SET_VEHICLE_COMPARISON", data.body.data);
      })
      .finally(() => (store.state.isLoading = false));
  },
  customerComarpisonList: (store, params) => {
    store.state.isLoading = true;
    return service
      .rider_aggregated(params)
      .then((data) => {
        store.commit("SET_CUSTOMER_COMPARISON", data.body.data);
      })
      .finally(() => (store.state.isLoading = false));
  },
};

const getters = {
  getIsLoading(state) {
    return state.isLoading;
  },
  getVehicleComparison(state) {
    return state.vehicleComparisonData;
  },
  getCustomerComparison(state) {
    return state.customerComparisonData;
  },
  getVehicleMeta(state) {
    return state.vehicleMeta;
  },
  getCustomerMeta(state) {
    return state.customerMeta;
  },
};

const vehicleTypes = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default vehicleTypes;
