import service from "@/store/services/md-models-service";

const state = {
  isLoadingMdModels: false,
  listMdModels: [],
  listMdModelbyID: {},
  meta: {},
};

const mutations = {
  SET_MODEL(state, mdModel) {
    state.listMdModels = mdModel;
  },
  SET_MODEL_BY_ID(state, mdModel) {
    state.listMdModelbyID = mdModel;
  },

  ADD_MODEL(state, mdModel) {
    state.listMdModels.push(mdModel);
  },

  UPDATE_MODEL(state, mdModel) {
    const item = state.listMdModels.find((item) => item.id === mdModel.id);
    Object.assign(item, mdModel);
  },

  DELETE_MODEL(state, mdModel) {
    state.listMdModels.splice(
      state.listMdModels.map((o) => o.id).indexOf(mdModel.id),
      1
    );
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingMdModels = true;
    service.list(params).then((data) => {
      store.commit("SET_MODEL", data.body.data);
      store.commit("SET_META", data.body);
      store.state.isLoadingMdModels = false;
    });
  },
  show: (store, params) => {
    store.state.isLoadingMdModels = true;
    service.show(params).then((data) => {
      store.commit("SET_MODEL_BY_ID", data.body);
      store.state.isLoadingMdModels = false;
    });
  },

  add: (store, { mdModel, filter }) => {
    return service.add(mdModel).then((mdModel) => {
      store.commit("ADD_MODEL", mdModel);
      store.dispatch("list", filter);
    });
  },

  update: (store, { mdModel, filter }) => {
    return service.update(mdModel).then((mdModel) => {
      store.commit("UPDATE_MODEL", mdModel);
      store.dispatch("list", filter);
    });
  },

  destroy: (store, mdModel) => {
    return service.destroy(mdModel).then(() => {
      store.commit("DELETE_MODEL", mdModel);
    });
  },
};

const getters = {
  isLoadingMdModels(state) {
    return state.isLoadingMdModels;
  },

  listMdModels(state) {
    return state.listMdModels;
  },
  getMdModelById(state) {
    return state.listMdModelbyID;
  },
  activelistMdModels() {
    return state.listMdModels.filter((mdModel) => mdModel.is_active == true);
  },
  meta(state) {
    return state.meta;
  },
};

const mdModels = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default mdModels;
