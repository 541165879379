import service from "../services/truckistan-api-service";

const state = {
  route: null,
  locations: [],
  isLocationLoading: false,
};

const mutations = {
  SET_ROUTE(state, payload) {
    state.route = payload;
    localStorage.removeItem("modelId");
    localStorage.removeItem("tracker");
  },
  SET_LOCATIONS(state, payload) {
    state.locations = payload;
  },
};

const actions = {
  setRoutePlan: (store, params) => {
    return service.setRoutePlan(params).then((data) => {
      return data;
    });
    // store.commit("SET_ROUTE", params);
  },
  getPlanOutput: (store, params) => {
    return service.planOutput(params).then((data) => {
      store.commit("SET_ROUTE", data);
    });
  },
  getLocations: (store, params) => {
    store.state.isLocationLoading = true;
    return service
      .routeLocations(params)
      .then((data) => {
        store.commit("SET_LOCATIONS", data.body.locations);
      })
      .finally(() => (store.state.isLocationLoading = false));
  },
  addLocation: (store, params) => {
    store.state.isLocationLoading = true;
    return service
      .addLocation(params)
      .then(() => {})
      .finally(() => (store.state.isLocationLoading = false));
  },
};

const getters = {
  getRoute(state) {
    return state.route;
  },
  getLocationOptions(state) {
    return state.locations;
  },
  getLocationsLoading(state) {
    return state.isLocationLoading;
  },
};

const truckistanRoute = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default truckistanRoute;
