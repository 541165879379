import service from "../services/flespi-calculator-service";

const state = {
  isLoading: false,
  calcsList: [],
  meta: {},
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_CALCS(state, calcsList) {
    state.calcsList = calcsList;
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoading = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_CALCS", data.body.data);
        store.commit("SET_META", data.body);
      })
      .finally(() => (store.state.isLoading = false));
  },

  calcSync: (store, params) => {
    store.state.isLoading = true;
    return service
      .calcSync(params)
      .then(() => {
        store.dispatch("list");
      })
      .finally(() => (store.state.isLoading = false));
  },
  bulkAssign: (store, params) => {
    store.state.isLoading = true;
    return service
      .bulkAssign(params)
      .then((data) => {
        return data.body;
      })
      .finally(() => (store.state.isLoading = false));
  },
};

const getters = {
  getMeta(state) {
    return state.meta;
  },
  getCalcsList(state) {
    return state.calcsList;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
};

const motorTypes = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default motorTypes;
