import service from "@/store/services/daily-mileage-reports-service";

const state = {
  loading: false,
  meta: {},
  list: [],
  summary: [],
  //

  isListExport: false,
  isSummaryExport: false,
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_SUMMARY(state, payload) {
    state.summary = payload;
  },
};

const actions = {
  list: (store, params) => {
    store.state.loading = true;
    service
      .list(params)
      .then((data) => {
        store.commit("SET_META", data.body);
        store.commit("SET_LIST", data.body.data);
      })
      .finally(() => (store.state.loading = false));
  },
  summary: (store, params) => {
    store.state.loading = true;
    service
      .summary(params)
      .then((data) => {
        store.commit("SET_SUMMARY", data.body.data);
      })
      .finally(() => (store.state.loading = false));
  },
  exportList: (store, params) => {
    store.state.isListExport = true;
    service
      .exportList(params)
      .then((data) => {
        if (params.type == "pdf") {
          window.open(data.body.file_url, "_blank");
        } else {
          const downloadLink = document.createElement("a");
          downloadLink.href = data.body.file_url;
          downloadLink.download = "filename"; // Set the desired file name here
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      })
      .finally(() => (store.state.isListExport = false));
  },
  exportSummary: (store, params) => {
    store.state.isSummaryExport = true;
    service
      .exportSummary(params)
      .then((data) => {
        if (params.type == "pdf") {
          window.open(data.body.file_url, "_blank");
        } else {
          const downloadLink = document.createElement("a");
          downloadLink.href = data.body.file_url;
          downloadLink.download = "filename"; // Set the desired file name here
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      })
      .finally(() => {
        store.state.isSummaryExport = false;
      });
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  meta(state) {
    return state.meta;
  },
  getJourneyList(state) {
    return state.list;
  },
  getSummary(state) {
    return state.summary;
  },
  //
  getIsListExport(state) {
    return state.isListExport;
  },
  getIsSummaryExport(state) {
    return state.isSummaryExport;
  },
};

const journeyReport = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default journeyReport;
