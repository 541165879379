import service from "../services/battery-diagnostics-service";

const state = {
  isLoading: false,
  meta: {},
  batterDiagnostic: [],
  headers: [],
  dashboard: {},
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_HEADERS(state, payload) {
    state.headers = payload;
  },
  SET_BATTERY_DIAGNOSTICS(state, payload) {
    state.batterDiagnostic = payload;
  },
  SET_BATTERY_DASHBOARD(state, payload) {
    state.dashboard = payload;
  },
};

const actions = {
  headers: (store, params) => {
    store.state.isLoading = true;
    return service
      .headers(params)
      .then((data) => {
        store.commit("SET_HEADERS", data.body.headers);
      })
      .finally(() => (store.state.isLoading = false));
  },
  list: (store, params) => {
    store.state.isLoading = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_META", data.body);
        store.commit("SET_BATTERY_DIAGNOSTICS", data.body.data);
      })
      .finally(() => (store.state.isLoading = false));
  },
  batteryTelematicList: (store, params) => {
    store.state.isLoading = true;
    return service
      .batteryTelematicList(params)
      .then((data) => {
        store.commit("SET_META", data.body.meta);
        store.commit("SET_BATTERY_DIAGNOSTICS", data.body.data);
      })
      .finally(() => (store.state.isLoading = false));
  },
  // dashboard
  dashboard2w: (store, params) => {
    store.state.isLoading = true;
    return service
      .dashboard2w(params)
      .then((data) => {
        store.commit("SET_BATTERY_DASHBOARD", data.body);
      })
      .finally(() => (store.state.isLoading = false));
  },
  dashboard3w: (store, params) => {
    store.state.isLoading = true;
    return service
      .dashboard3w(params)
      .then((data) => {
        store.commit("SET_BATTERY_DASHBOARD", data.body);
      })
      .finally(() => (store.state.isLoading = false));
  },
};

const getters = {
  getIsLoading(state) {
    return state.isLoading;
  },
  getMeta(state) {
    return state.meta;
  },
  getHeaders(state) {
    return state.headers;
  },
  getBatteryDiagnostics(state) {
    return state.batterDiagnostic;
  },
  getBatteryDashboard(state) {
    return state.dashboard;
  },
};

const batterDiagnostics = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default batterDiagnostics;
