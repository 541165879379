import service from "../services/battery-models-service";

const state = {
  batteryModelsList: [],
  isLoadingBatteryModels: false,
};

const mutations = {
  SET_BATTERY_MODELS(state, batteryModels) {
    state.batteryModelsList = batteryModels;
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingBatteryModels = true;
    return service.list(params).then((data) => {
      store.commit("SET_BATTERY_MODELS", data.body.data);
      store.state.isLoadingBatteryModels = false;
    });
  },
};

const getters = {
  batteryModelsList(state) {
    return state.batteryModelsList;
  },
  isLoadingBatteryModels(state){
    return state.isLoadingBatteryModels;
  }
};

const batteryModels = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default batteryModels;
