export default {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  accessToken(state) {
    return state.accessToken;
  },
  getUser(state) {
    return state.user ? state.user : JSON.parse(localStorage.getItem("user"));
  },
  getPermissions(state) {
    const user = state.user
      ? state.user
      : JSON.parse(localStorage.getItem("user"));
    const permissions = [];
    if (user && user.permissions) {
      for (const [key, value] of Object.entries(user.permissions)) {
        permissions.push({ id: key, name: value });
      }
    }
    return permissions;
  },
};