import service from "../services/permissions-service";

const state = {
  isLoadingPermissions: false,
  listPermissions: [],
  tiersOptions: [],
  meta: {},
};

const mutations = {
  SET_PERMISSIONS(state, listPermissions) {
    state.listPermissions = listPermissions;
  },
  SET_TIERS_OPTIONS(state, payload) {
    state.tiersOptions = payload
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingPermissions = true;
    service.list(params).then((data) => {
      store.commit("SET_PERMISSIONS", data.body.permissions);
      store.commit("SET_META", data);
      store.state.isLoadingPermissions = false;
    });
  },
  tiersOptions: (store, params) => {
    service.productTiersOption(params).then((data) => {
      store.commit("SET_TIERS_OPTIONS", data.body.data)
    })
  },
  tiersPermissionList: (store, params) => {
    store.state.isLoadingPermissions = true;
    service.productTiersPermission(params).then((data) => {
      store.commit("SET_PERMISSIONS", data.body.permissions);
      store.commit("SET_META", data);
      store.state.isLoadingPermissions = false;
    });
  },
};

const getters = {
  isLoadingPermissions(state) {
    return state.isLoadingPermissions;
  },

  listPermissions(state) {
    return state.listPermissions;
  },
  getTiersOptions(state) {
    return state.tiersOptions
  },
  meta(state) {
    return state.meta;
  },
};

const permissions = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default permissions;
