<template>
  <Datetime
    v-model="dt"
    type="datetime"
    class="date-time"
    :use12h="false"
    :disabled-date="disabledDate"
    @change="onDateChange"
  >
    <template #input>
      <v-text-field
        :value="formattedDate"
        dense
        :label="label"
        readonly
      />
    </template>
    <template #icon-calendar>
      <i />
    </template>
  </Datetime>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import moment from "moment";

export default {
  components: { Datetime: DatePicker },
  props: {
    value: {
      type: String,
      default() {
        return "label";
      },
    },
    label: {
      type: String,
      default() {
        return "label";
      },
    },
    min: {
      type: String,
      default() {
        return "";
      },
    },
    max: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      menu: false, // Control for v-menu visibility
      dt: "", // Raw date from the Datetime picker
    };
  },
  computed: {
    formattedDate() {
      if (this.dt) {
        return moment(this.dt).format("DD-MMM-YYYY hh:mm:ss a");
      } else return "";
    },
  },
  mounted() {
    if (this.value) {
      this.dt = new Date(this.value);
      this.emitData();
    }
  },
  methods: {
    onDateChange(value) {
      if (value) {
        this.dt = value; // Update the raw date
        this.emitData();
      }
    },
    formatDate(value) {
      if (value) {
        return moment(value).format("YYYY-MM-DD hh:mm:ss a"); // Format using Moment.js
      }
      return "";
    },
    disabledDate(dd) {
      const date = moment(dd);
      //   const min = moment("2024-10-10 15:15:20");
      //   //   const max = moment("2024-10-11 22:55:30");
      //   const max = "";
      const min = this.min ? moment(this.min) : "";
      const max = this.min ? moment(this.max) : "";

      return date.isBefore(min, "second") || date.isAfter(max, "second");
    },
    emitData() {
      const dateTime = moment(this.dt).format("YYYY-MM-DD HH:mm:ss");
      this.$emit("input", dateTime);
      this.$emit("change", dateTime);
    },
    // emit
    // 2024-10-15
  },
};
</script>

<style lang="sass" scoped>
.date-time
    width: 100%
    border-radius: 0 !important

:deep i
    margin-top: -1rem

.v-text-field
    font-size: 14px
</style>
