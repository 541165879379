import service from "../services/vehicle-trips-service";

const state = {
  isLoading: false,
  list: [],
  detail: {},
  meta: {},
  //
  tripDashboard: {},
  tripDashboardChart: [],
  //
  tripTelematicsHeaders: [],
  tripTelematicsMeta: {},
  tripTelematicsList: [],
};

const mutations = {
  SET_VEHICEL_TRIPS(state, payload) {
    state.list = payload;
  },
  SET_VEHICEL_TRIPS_DETAIL(state, payload) {
    state.detail = payload;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  //
  SET_TRIP_DASHBOARD(state, payload) {
    state.tripDashboard = payload;
  },
  SET_TRIP_CHART(state, payload) {
    state.tripDashboardChart = payload;
  },
  //
  SET_TRIP_TELEMATIC_HEADERS(state, payload) {
    state.tripTelematicsHeaders = payload;
  },
  SET_TRIP_TELEMATIC_META(state, data) {
    if (data.meta) {
      state.tripTelematicsMeta.page = data.meta.current_page;
      state.tripTelematicsMeta.itemsPerPage = data.meta.per_page;
      state.tripTelematicsMeta.lastPage = data.meta.last_page;
      state.tripTelematicsMeta.totalItems = data.meta.total;
    }
  },
  SET_TRIP_TELEMATIC_LIST(state, payload) {
    state.tripTelematicsList = payload;
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoading = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_VEHICEL_TRIPS", data.body.data);
        store.commit("SET_META", data.body);
      })
      .finally(() => (store.state.isLoading = false));
  },
  detail: (store, params) => {
    store.state.isLoading = true;
    return service
      .details(params)
      .then((data) => {
        store.commit("SET_VEHICEL_TRIPS_DETAIL", data.body);
      })
      .finally(() => (store.state.isLoading = false));
  },
  update: (store, params) => {
    return service.update(params).then(() => {
      // store.commit("SET_VEHICEL_TRIPS_DETAIL", data.body);
    });
  },
  updateSOC: (store, params) => {
    return service.updateSOC(params).then(() => {});
  },
  //
  tripsDashboard: (store, params) => {
    store.state.isLoading = true;

    return service
      .tripsDashboard(params)
      .then((data) => {
        store.commit("SET_TRIP_DASHBOARD", data.body.data);
      })
      .finally(() => (store.state.isLoading = false));
  },
  tripsDashboardChart: (store, params) => {
    store.state.isLoading = true;

    return service
      .tripsChart(params)
      .then((data) => {
        store.commit("SET_TRIP_CHART", data.body.data);
      })
      .finally(() => (store.state.isLoading = false));
  },
  //

  tripTelematicsHeaders: (store, params) => {
    store.state.isLoading = true;
    return service.tripTelematicsHeaders(params).then((data) => {
      store.commit("SET_TRIP_TELEMATIC_HEADERS", data.body.headers);
      store.state.isLoading = false;
    });
  },

  tripTelematicsList: (store, params) => {
    store.state.isLoading = true;
    return service.tripTelematicsList(params).then((data) => {
      store.commit("SET_TRIP_TELEMATIC_META", data.body);
      store.commit("SET_TRIP_TELEMATIC_LIST", data.body.data);
      store.state.isLoading = false;
    });
  },
};

const getters = {
  getIsLoading(state) {
    return state.isLoading;
  },
  getMeta(state) {
    return state.meta;
  },
  getVehicleTripList(state) {
    return state.list;
  },
  getVehicleTripDetail(state) {
    return state.detail;
  },
  //
  getTripDashboard(state) {
    return state.tripDashboard;
  },
  getTripDashboardChart(state) {
    return state.tripDashboardChart;
  },
  //
  getTripTelematicsHeaders() {
    return state.tripTelematicsHeaders;
  },
  getTripTelematicsMeta() {
    return state.tripTelematicsMeta;
  },
  getTripTelematicsList() {
    return state.tripTelematicsList;
  },
};

const vehicleTrips = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default vehicleTrips;
