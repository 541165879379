import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function dashboardData(params) {
  return axios.get(`${base_url}dashboard-data`, { params }).then((response) => {
    return response.data;
  });
}

async function activeTripsDetail(params) {
  return axios
    .get(`${base_url}active-trip-detail`, { params })
    .then((response) => {
      return response.data;
    });
}

//
async function dashboardCardOptions(params) {
  return axios
    .get(`${base_url}dashboard-cards`, { params })
    .then((response) => {
      return response.data;
    });
}

async function updateCard(params) {
  return axios
    .post(`${base_url}user-dashboard-cards`, params)
    .then((response) => {
      return response.data;
    });
}

async function userDashboardCards(params) {
  return axios
    .get(`${base_url}user-dashboard-cards`, { params })
    .then((response) => {
      return response.data;
    });
}
export default {
  dashboardData,
  activeTripsDetail,
  dashboardCardOptions,
  updateCard,
  userDashboardCards,
};
