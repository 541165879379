import service from "../services/flespi-command-service";

const state = {
  isLoading: false,
  flespiCommands: [],
};

const mutations = {
  
  SET_FLESPI_COMMANDS(state, payload) {
    state.flespiCommands = payload;
  },
};

const actions = {
  flespiCommandsOptions: (store, params) => {
    store.state.isLoading = true;
    return service.getFlespiCommands(params).then((data) => {
      store.commit("SET_FLESPI_COMMANDS", data.body.data);
    }).finally(()=> store.state.isLoading=false);
  },
  sendFlespiCommand: (store, params) => {
    store.state.isLoading = true;
    return service.setFlespiCommands(params).then(() => {
      // store.commit("SET_FLESPI_COMMANDS", data.body.data);
    }).finally(()=> store.state.isLoading=false);
  },

  
};

const getters = {
  
  getIsLoading(state){
    return state.isLoading
  },
  getFlespiCommands(state) {
    return state.flespiCommands;
  },
};

const flespiCommands = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default flespiCommands;
