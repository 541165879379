import service from "../services/vehicle-charge-session-service";

const state = {
  isLoading: false,
  meta:{},
  list: [],
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_VEHICEL_CHARGE_SESSION(state, payload) {
    state.list = payload;
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoading = true;
    return service.list(params).then((data) => {
      store.commit("SET_VEHICEL_CHARGE_SESSION", data.body.data);
      store.commit("SET_META", data.body)
    }).finally(()=> store.state.isLoading = false);
  },
  
};

const getters = {
  getMeta(state){
    return state.meta
  },
  getChargeSessionList(state){
    return state.list;
  },
  getIsLoading(state){
    return state.isLoading
  }
};

const vehicleChargeSession = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default vehicleChargeSession;
