import service from "../services/product-service";

const state = {
  productList: [],
  productWithTiers: [],
};

const mutations = {
  SET_PRODUCT_LIST(state, payload) {
    state.productList = payload;
  },
  SET_PRODUCT_WITH_TIERS(state, payload) {
    state.productWithTiers = payload;
  },
};

const actions = {
  list: (store, params) => {
    return service.list(params).then((data) => {
      store.commit("SET_PRODUCT_LIST", data.body.data);
    });
  },
  product_with_tiers: (store, params) => {
    return service.product_with_tiers(params).then((data) => {
      store.commit("SET_PRODUCT_WITH_TIERS", data.body.data);
    });
  },
};

const getters = {
  getProductList(state) {
    return state.productList;
  },
  getProductWithTiers(state) {
    return state.productWithTiers;
  },
};

const product = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default product;
