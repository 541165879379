import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {

  return axios.get(`${base_url}cvp-support-tickets`, { params }).then((response) => {

    return response.data;
  });
}

async function show(supportTicket) {
  return axios.get(`${base_url}cvp-support-tickets/${supportTicket.id}`).then((response) => {
    return response.data;
  });
}

async function add(supportTicket) {
  return axios.post(`${base_url}cvp-support-tickets`, supportTicket).then((response) => {
    return response.data;
  });
}

async function update(supportTicket) {
  return axios.put(`${base_url}cvp-support-tickets/${supportTicket.id}`, supportTicket).then((response) => {
    return response.data;
  });
}

async function destroy(supportTicket) {

  return axios.delete_method(`${base_url}cvp-support-tickets/${supportTicket.id}`).then((response) => {
    return response.data;
  });
}

export default {
  list,
  show,
  add,
  update,
  destroy,
};
