import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios
    .get(`${base_url}vehicle-maintenance-logs`, { params })
    .then((response) => {
      return response.data;
    });
}

async function add(params) {
  return axios
    .post(`${base_url}vehicle-maintenance-logs`, params)
    .then((response) => {
      return response.data;
    });
}

async function update(params) {
  return axios
    .put(`${base_url}vehicle-maintenance-logs/${params.id}`, params)
    .then((response) => {
      return response.data.body;
    });
}

async function destroy(params) {
  return axios
    .delete_method(`${base_url}vehicle-maintenance-logs/${params.id}`, params)
    .then((response) => {
      return response.data.body;
    });
}

async function maintenanceServices(params) {
  return axios
    .get(`${base_url}maintenance-services`, { params })
    .then((response) => {
      return response.data;
    });
}
export default {
  list,
  add,
  update,
  destroy,
  maintenanceServices,
};
