<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
};
</script>
