import service from "../services/support-tickets-service";
const state = {
  isLoadingSupportTickets: false,
  listSupportTickets: [],
  supportTicket: {},
  meta: {},
  editedDevice: {},
  isVisibleEditDialog: false,

  ticketStatusList: ["pending", "in_progress", "resolved"],
  ticketCategoryList: [
    "battery",
    "ignition",
    "engine",
    "parts_replacement",
    "mobile_app_issues",
    "range_issue",
    "other",
  ],
};

const mutations = {
  SET_SUPPORTTICKETS(state, listSupportTickets) {
    state.listSupportTickets = listSupportTickets;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  ADD_SUPPORTTICKET(state, supportTicket) {
    state.listSupportTickets.push(supportTicket);
  },
  IS_VISIBLE_EDIT_DIALOG(state, value) {
    state.isVisibleEditDialog = value;
  },
  EDITED_SUPPORTTICKET(state, supportTicket) {
    state.editedSupportTickets = supportTicket;
  },
  UPDATE_SUPPORTTICKET(state, supportTicket) {
    const item = state.listSupportTickets.find(
      (item) => item.id === supportTicket.id
    );
    Object.assign(item, supportTicket);
  },
  DELETE_SUPPORTTICKET(state, supportTicket) {
    state.listSupportTickets.splice(
      state.listSupportTickets.map((o) => o.id).indexOf(supportTicket.id),
      1
    );
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingSupportTickets = true;
    service.list(params).then((data) => {
      store.commit("SET_SUPPORTTICKETS", data.body);
      store.commit("SET_META", data.body);
      store.state.isLoadingSupportTickets = false;
    });
  },

  add: (store, supportTicket) => {
    return service.add(supportTicket).then((data) => {
      store.commit("ADD_SUPPORTTICKET", data.body);
    });
  },

  update: (store, supportTicket) => {
    return service.update(supportTicket).then((supportTicket) => {
      store.commit("UPDATE_SUPPORTTICKET", supportTicket.body);
    });
  },

  destroy: (store, supportTicket) => {
    return service.destroy(supportTicket).then(() => {
      store.commit("DELETE_SUPPORTTICKET", supportTicket);
    });
  },
};

const getters = {
  getSupportTicketsList(state) {
    return state.listSupportTickets;
  },
  getSupportTicketsMeta(state) {
    return state.meta;
  },
  getIsLoadingSupportTickets(state) {
    return state.isLoadingSupportTickets;
  },

  ticketStatusLists(state) {
    return state.ticketStatusList;
  },

  ticketCategoryLists(state) {
    return state.ticketCategoryList;
  },
};

const supportTickets = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default supportTickets;
