<template>
  <v-btn
    class="reload-btn"
    :class="isLoading ? 'active' : ''"
    outlined
    small
    :disabled="isDisable"
    @click="reload"
  >
    <v-icon>mdi-reload</v-icon>
  </v-btn>
  <!-- <div
    class="reload-btn"
    :class="isLoading ? 'active' : ''"
  >
    <v-icon @click="reload">
      mdi-reload
    </v-icon>
  </div> -->
</template>
<script>
export default {
  name: "RefreshButton",
  props: {
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      isDisable: false,
    };
  },
  methods: {
    reload() {
      this.$emit("reload");
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 5000);
    },
  },
};
</script>
<style scoped>
.reload-btn {
  height: 41px !important;
  padding: 0 0.7rem !important;
  min-width: unset !important;
  background: #fff;
  border: 1px solid #DADADA !important;
  border-radius: 6px !important;
}
.reload-btn i
{
  font-size: 16px !important;
}
.reload-btn:hover i,
.reload-btn:active i,
.reload-btn:focus i,
.reload-btn:focus-visible i,
.reload-btn:focus-within i
{
  color: #000 !important;
}
button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  padding: 0 0.7rem !important;
  min-width: unset !important;
}
.active button,
.active i {
  animation: spin 0.9s infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
