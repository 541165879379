import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios.get(`${base_url}flespi-calcs`, { params }).then((response) => {
    return response.data;
  });
}

async function calcSync(params) {
  return axios
    .get(`${base_url}flespi-calcs/sync`, { params })
    .then((response) => {
      return response.data;
    });
}

async function bulkAssign(params) {
  return axios
    .post(`${base_url}assign-flespi-calculators-to-tenant`, params)
    .then((response) => {
      return response.data;
    });
}

export default {
  list,
  calcSync,
  bulkAssign,
};
