// import service from "../services/meta-service";
import cpoClientService from "../services/cpoclient-service";
import oemclientService from "../services/oemclient-service";
import tenantsManagementService from "../services/tenants-management-service";

const state = {
  clientsList: [],
  isLoadingClient: false,
};

const mutations = {
  SET_CLIENTS(state, clients) {
    state.clientsList = clients;
  },
};

const actions = {
  getClients: (store, userType) => {
    store.state.isLoadingClient = true;
    if (userType == "cpo") {
      return cpoClientService.list().then((data) => {
        store.commit("SET_CLIENTS", data.body.data);
        store.state.isLoadingClient = false;
      });
    }
    if (userType == "oem") {
      return oemclientService.list().then((data) => {
        store.commit("SET_CLIENTS", data.body.data);
        store.state.isLoadingClient = false;
      });
    }
    if (userType == "tenant") {
      return tenantsManagementService.list({ is_active: true }).then((data) => {
        store.commit("SET_CLIENTS", data.body.data);
        store.state.isLoadingClient = false;
      });
    }
  },
};

const getters = {
  clientsList(state) {
    return state.clientsList;
  },
  clientListsByType: (state) => (type) => {
    return state.clientsList.filter((client) => client.type == type);
  },
  isLoadingClient(state) {
    return state.isLoadingClient;
  },
};

const meta = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default meta;
