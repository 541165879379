import service from "../services/iot-device-makes-service";

const state = {
  iotDeviceMakesList: [],
};

const mutations = {
  SET_IOTDEVICEMAKES(state, iotDeviceMakes) {
    state.iotDeviceMakesList = iotDeviceMakes;
  },
};

const actions = {
  list: (store, params) => {
    return service.list(params).then((data) => {
      store.commit("SET_IOTDEVICEMAKES", data.body.data);
    });
  },
};

const getters = {
  iotDeviceMakesList(state) {
    return state.iotDeviceMakesList;
  },
};

const iotDeviceMakes = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default iotDeviceMakes;
