import service from "../services/vehicle-service";
import pService from "@/helpers/service";
const state = {
  isLoadingVehicles: false,
  listVehicles: [],
  vehicle: {},
  meta: {},
  editedDevice: {},
  isVisibleEditDialog: false,
  isLoadingVehicle: false,

  //
  isThirdParty: false,
  thirdPartyList: [],
};

const mutations = {
  SET_VEHICLES(state, listVehicles) {
    state.listVehicles = listVehicles;
  },
  SET_META(state, data = { meta: null }) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    } else {
      state.meta = {};
    }
  },
  ADD_VEHICLE(state, vehicle) {
    state.listVehicles.push(vehicle);
  },
  IS_VISIBLE_EDIT_DIALOG(state, value) {
    state.isVisibleEditDialog = value;
  },

  SET_VEHICLE(state, vehicle) {
    state.vehicle = vehicle;
  },

  EDITED_VEHICLE(state, vehicle) {
    state.editedVehicles = vehicle;
  },
  UPDATE_VEHICLE(state, vehicle) {
    state.listVehicles = state.listVehicles.map((r) =>
      r.id == vehicle.id ? vehicle : r
    );
  },
  DELETE_VEHICLE(state, vehicle) {
    state.listVehicles.splice(
      state.listVehicles.map((o) => o.id).indexOf(vehicle.id),
      1
    );
  },

  //
  SET_THIRD_PARTY_LIST(state, payload) {
    state.thirdPartyList = payload;
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingVehicles = true;
    service.list(params).then((data) => {
      store.commit("SET_VEHICLES", data.body.data);
      store.commit("SET_META", data.body);
      store.state.isLoadingVehicles = false;
    });
  },
  vehiclesList: (store, params) => {
    store.state.isLoadingVehicles = true;
    return pService.get("/vehicle/get-vehicles", params).then((data) => {
      store.commit("SET_VEHICLES", data.body);
      store.state.isLoadingVehicles = false;
    });
  },
  vehicleDropDown: (store, params) => {
    store.state.isLoadingVehicles = true;
    return pService
      .get("vehicle/get-dropdown-with-filters", params)
      .then((data) => {
        store.commit("SET_VEHICLES", data.body);
        store.state.isLoadingVehicles = false;
      });
  },

  add: (store, params) => {
    return service.add(params).then((data) => {
      store.commit("ADD_VEHICLE", data.body);
      // if (filter.isAdmin) {
      //   if (filter.tenant_id) {
      //     store.commit("ADD_VEHICLE", data.body);
      //     store.dispatch("list", filter);
      //   }
      // } else {
      //   store.commit("ADD_VEHICLE", data.body);
      //   store.dispatch("list", filter);
      // }
    });
  },

  show: (store, vehicleId) => {
    store.state.isLoadingVehicle = true;
    return service.show(vehicleId).then((data) => {
      store.commit("SET_VEHICLE", data.body);
      store.state.isLoadingVehicle = false;
    });
  },

  update: (store, vehicle) => {
    return service.update(vehicle).then((vehicle) => {
      store.commit("UPDATE_VEHICLE", vehicle.body);
      // store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  destroy: (store, vehicle) => {
    return service.destroy(vehicle).then(() => {
      store.commit("DELETE_VEHICLE", vehicle);
    });
  },

  assignCustomers: (store, vehicle) => {
    return service.assignCustomer(vehicle).then((vehicle) => {
      store.commit("UPDATE_VEHICLE", vehicle.body);
      return vehicle.body;
      // store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },
  bulkAssignToBusiness: (store, vehicles) => {
    return service.bulkAssignToBusiness(vehicles);
  },

  //

  thirdPartiesList: (store, params) => {
    store.state.isThirdParty = true;
    return service.thirdPartiesList(params).then((data) => {
      store.commit("SET_THIRD_PARTY_LIST", data.body.third_parties_list);
      store.state.isThirdParty = false;
    });
  },
};

const getters = {
  getVehiclesList(state) {
    return state.listVehicles;
  },
  getVehiclesMeta(state) {
    return state.meta;
  },
  getIsLoadingVehicles(state) {
    return state.isLoadingVehicles;
  },
  getVehicle(state) {
    return state.vehicle;
  },

  isLoadingVehicle(state) {
    return state.isLoadingVehicle;
  },

  //

  isThirdParty(state) {
    return state.isThirdParty;
  },
  getThirdPartyList(state) {
    return state.thirdPartyList;
  },
};

const vehicles = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default vehicles;
