import service from "../services/table-headers-service";

const state = {
  headerList: {
    columns:[],
    dt_name:"",
    id:null
  },
  payloadHeader: [], //only for the payload
  isLoading : false
};

const mutations = {
  SET_DATA_HEADERS(state, payLoad) {
    state.headerList = payLoad;
    state.payloadHeader = [...payLoad.columns]; //clone same headers
  },
  UPDATE_DATA_HEADERS(state, payLoad) {
    if(payLoad.selected){
      // state.headerList.columns.push(payLoad.key)
      state.payloadHeader.push(payLoad.key)
    }
    else{
      // const index = state.headerList.columns.indexOf(payLoad.key)
      const index = state.payloadHeader.indexOf(payLoad.key)
      if (index > -1) { // only splice array when item is found
        // state.headerList.columns.splice(index, 1); // 2nd parameter means remove one item only
        state.payloadHeader.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
  },
};

const actions = {
  selectedlist: (store, params) => {
    store.state.isLoading = true;
    return service.list(params).then((data) => {
      store.commit("SET_DATA_HEADERS", data.body);
      store.state.isLoading = false;
    });
  },
  update: (store, params) => {
    store.state.isLoading = true;
    return service.update(params).then(() => {
      // store.commit("SET_DATA_HEADERS", res.body);
      store.state.isLoading = false;
    });
  },
};

const getters = {
  getDataHeaders(state) {
    return state.headerList;
  },
  getLoading(state){
    return state.isLoading
  },
  getPayloadHeaders(state){
return state.payloadHeader
  }
};

const tableHeaders = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default tableHeaders;
