import service from "@/store/services/data-sims-service";

const state = {
  isLoadingDataSims: false,
  listDataSims: [],
  meta: {},
};

const mutations = {
  SET_SIMS(state, dataSimss) {
    state.listDataSims = dataSimss;
  },

  ADD_SIM(state, dataSim) {
    state.listDataSims.push(dataSim);
  },

  UPDATE_SIMS(state, dataSim) {
    const item = state.listDataSims.find((item) => item.id === dataSim.id);
    Object.assign(item, dataSim);
  },

  DELETE_SIM(state, dataSim) {
    state.listDataSims.splice(
      state.listDataSims.map((o) => o.id).indexOf(dataSim.id),
      1
    );
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingDataSims = true;
    service.list(params).then((data) => {
      store.commit("SET_SIMS", data.body.data);
      store.commit("SET_META", data.body);
      store.state.isLoadingDataSims = false;
    });
  },

  add: (store, dataSim) => {
    return service.add(dataSim).then((dataSim) => {
      store.commit("ADD_SIM", dataSim);
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  update: (store, dataSim) => {
    return service.update(dataSim).then((dataSim) => {
      store.commit("UPDATE_SIMS", dataSim);
    });
  },

  destroy: (store, dataSim) => {
    return service.destroy(dataSim).then(() => {
      store.commit("DELETE_SIM", dataSim);
    });
  },
};

const getters = {
  isLoadingDataSims(state) {
    return state.isLoadingDataSims;
  },

  listDataSims(state) {
    return state.listDataSims;
  },
  activeListDataSims(state) {
    return state.listDataSims.filter((dataSim) => dataSim.is_active == true);
  },

  meta(state) {
    return state.meta;
  },
};

const dataSims = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default dataSims;
