import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios
    .get(`${base_url}customer-vehicles`, { params })
    .then((response) => {
      return response.data;
    });
}

async function show(customerVehicle) {
  return axios
    .get(`${base_url}customer-vehicles/${customerVehicle.id}`)
    .then((response) => {
      return response.data;
    });
}

async function add(customerVehicle) {
  return axios
    .post(`${base_url}customer-vehicles`, customerVehicle)
    .then((response) => {
      return response.data;
    });
}

async function update(customerVehicle) {
  return axios
    .put(`${base_url}customer-vehicles/${customerVehicle.id}`, customerVehicle)
    .then((response) => {
      return response.data;
    });
}

async function destroy(customerVehicle) {
  return axios
    .delete_method(`${base_url}customer-vehicles/${customerVehicle.id}`)
    .then((response) => {
      return response.data;
    });
}

async function getHeadersList(params) {
  return axios
    .get(`${base_url}customer-vehicles/headers-list`, { params })
    .then((response) => {
      return response.data;
    });
}

async function downloadTelematicReports(params) {
  return axios
    .post(`${base_url}download-telematics-report`, params)
    .then((response) => {
      return response.data;
    });
}

export default {
  list,
  show,
  add,
  update,
  destroy,
  getHeadersList,
  downloadTelematicReports,
};
