import axios from "@/helpers/axios";
const adx_url = process.env.VUE_APP_BASEURL;


async function list(params) {
  return axios
    .get(`${adx_url}roles-and-permissions/permissions`, { params })
    .then((response) => {
      return response.data;
    });
}

async function productTiersOption(params) {
  return axios
    .get(`${adx_url}roles-and-permissions/product-tiers`, { params })
    .then((response) => {
      return response.data;
    });
}

async function productTiersPermission(params) {
  return axios
    .get(`${adx_url}roles-and-permissions/product-tier-permissions`, { params })
    .then((response) => {
      return response.data;
    });
}

// Url  : product-tier-permissions?product_tier_id=1
// Method : Get

export default {
  list,
  productTiersOption,
  productTiersPermission
};
