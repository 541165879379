import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {

  return axios.get(`${base_url}iot-devices`, { params }).then((response) => {

    return response.data;
  });
}

async function show(iotDevice) {
  return axios.get(`${base_url}iot-devices/${iotDevice.id}`).then((response) => {
    return response.data;
  });
}

async function add(iotDevice) {
  return axios.post(`${base_url}iot-devices`, iotDevice).then((response) => {
    return response.data;
  });
}

async function update(iotDevice) {
  return axios.put(`${base_url}iot-devices/${iotDevice.id}`, iotDevice).then((response) => {
    return response.data;
  });
}

async function destroy(iotDevice) {

  return axios.delete_method(`${base_url}iot-devices/${iotDevice.id}`).then((response) => {
    return response.data;
  });
}

async function getFlespiPlugins(params) {
  return axios.get(`${base_url}flespi/plugins`, { params }).then((response) => {
    return response.data;
  });
}


async function getFlespiStreams(params) {
  return axios.get(`${base_url}flespi/streams`, { params }).then((response) => {
    return response.data;
  });
}

export default {
  list,
  show,
  add,
  update,
  destroy,
  getFlespiPlugins,
  getFlespiStreams,
};
