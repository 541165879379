import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list({id, name}) {
  return axios.get(`${base_url}datatable-columns-selection?tenant_id=${id}&dt_name=${name}`).then((response) => {
    return response.data;
  });
}

async function update(params) {
  return axios.post(`${base_url}datatable-columns-selection`,params).then((response) => {
    return response.data;
  });
}



export default {
  list,
  update
};
