<template>
  <div class="relative copy-content">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            class="copy-content-icon"
            small
            @click="clickToCopy"
          >mdi mdi-content-copy
          </v-icon>
        </span>
      </template>
      <span>{{ content }}</span>
    </v-tooltip>

    <v-text-field
      ref="myText"
      :value="content"
      readonly
      style="width: 1px; height: 1px"
    />
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  methods: {
    clickToCopy() {
      const input = this.$refs.myText.$refs.input;
      input.select();
      document.execCommand("copy");
      input.setSelectionRange(0, 0); // unselect
    },
  },
};
</script>
<style lang="sass" scoped>
// .copy-content
//     overflow: hidden
.copy-content-icon
    cursor: pointer
    position: absolute
    display: flex
    justify-content: center
    width: 100%
    align-items: center
    height: 100%
    &:hover
        color: #20a390  !important
</style>
