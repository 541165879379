import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";
// import "@mdi/font/css/materialdesignicons.css";
// import '@/sass/overrides.sass'
// import mdiIcons from "@mdi/js"; // Import all MDI icons from @mdi/js
import "@mdi/font/css/materialdesignicons.min.css"; // Import the Material Design Icons CSS

Vue.use(Vuetify);

const theme = {
  primary: "#20a390",
  secondary: "#9C27b0",
  accent: "#9C27b0",
  info: "#00CAE3",
};
export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  // icons: {
  //   iconfont: "mdiSvg", // Use mdiSvg as the iconfont type for Vuetify
  //   values: mdiIcons, // Register mdiIcons object (from @mdi/js) with Vuetify
  // },
});
