import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios.get(`${base_url}locations`, { params }).then((response) => {
    return response.data;
  });
}

async function add(payload) {
  return axios.post(`${base_url}locations`, payload).then((response) => {
    return response.data;
  });
}
async function update(params) {
  return axios
    .put(`${base_url}locations/${params.id}`, params)
    .then((response) => {
      return response.data.body;
    });
}
async function destroy(params) {
  return axios
    .delete_method(`${base_url}locations/${params.id}`, params)
    .then((response) => {
      return response.data.body;
    });
}

// For Location Images
async function locationImgList(params) {
  return axios
    .get(`${base_url}location-images`, { params })
    .then((response) => {
      return response.data;
    });
}
async function addLocationImage(payload) {
  return axios
    .post(`${base_url}location-images`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    });
}

async function destroyLocationImage(params) {
  return axios
    .delete_method(`${base_url}location-images/${params.id}`, params)
    .then((response) => {
      return response.data.body;
    });
}

//  For location Amenities
async function amenities(params) {
  return axios.get(`${base_url}amenities`, { params }).then((response) => {
    return response.data;
  });
}
async function amenitiesList(params) {
  return axios
    .get(`${base_url}location-amenities`, { params })
    .then((response) => {
      return response.data;
    });
}
async function addAmenities(payload) {
  return axios
    .post(`${base_url}location-amenities`, payload)
    .then((response) => {
      return response.data;
    });
}
async function destroyAmenities(params) {
  return axios
    .delete_method(`${base_url}location-amenities/${params.id}`, params)
    .then((response) => {
      return response.data.body;
    });
}
export default {
  list,
  add,
  update,
  destroy,
  //
  locationImgList,
  addLocationImage,
  destroyLocationImage,
  // show,
  amenities,
  amenitiesList,
  addAmenities,
  destroyAmenities,
};
