<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="800px"
  >
    <v-card
      elevation="4"
      class="mt-0"
    >
      <v-tabs
        v-model="tab"
        @change="tabChanged(tab)"
      >
        <v-tab
          v-for="item in items"
          :key="item.tab"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item.tab"
        >
          <v-card-text>
            <v-container>
              <div v-if="item.content == 'view-make'">
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="mdMake.name"
                      dense
                      disabled
                      :label="$t('name_*')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="mdMake.status"
                      dense
                      disabled
                      :label="$t('status_*')"
                    />
                  </v-col>
                  <v-col
                    v-if="authUser.isAdmin"
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="mdMake.make_type"
                      disabled
                      dense
                      :label="$t('make_type_*')"
                    />
                  </v-col>
                  <v-col
                    v-if="authUser.isAdmin"
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="vehicle.tenant_name"
                      disabled
                      dense
                      :label="$t('select_tenant_*')"
                    />
                  </v-col>
                </v-row>
              </div>
              <div v-if="item.content == 'view-model'">
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.name"
                      dense
                      disabled
                      :label="$t('name_*')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.status"
                      dense
                      disabled
                      :label="$t('status_*')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.md_make_name"
                      dense
                      disabled
                      :label="$t('ev_make_*')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.vehicle_type_name"
                      dense
                      disabled
                      :label="$t('vehicle_type_*')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.battery_make_name"
                      dense
                      disabled
                      :label="$t('battery_make_*')"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.battery_model_name"
                      dense
                      disabled
                      :label="$t('battery_model_*')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.motor_type_name"
                      dense
                      disabled
                      :label="$t('motor_type_*')"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.battery_slot_type"
                      dense
                      disabled
                      :label="$t('battery_slot_type_*')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.start_year"
                      dense
                      disabled
                      type="number"
                      :label="$t('start_year_*')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.end_year"
                      dense
                      disabled
                      type="number"
                      :label="$t('end_year')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.no_of_batteries"
                      dense
                      disabled
                      type="number"
                      :label="$t('no_of_batteries_*')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.motor_power"
                      dense
                      disabled
                      :label="$t('motor_power')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.battery_capacity"
                      dense
                      disabled
                      type="number"
                      :label="$t('battery_capacity')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.fuel_economy"
                      dense
                      disabled
                      type="number"
                      :label="$t('fuel_economy')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.comp_fuel_avg_kmpl"
                      dense
                      type="number"
                      disabled
                      :label="$t('comp_fuel_avg_KMPL')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="mdModel.mileage"
                      dense
                      disabled
                      type="number"
                      :label="$t('mileage_*')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    class="py-0"
                  >
                    <v-img
                      lazy-src="https://picsum.photos/id/11/10/6"
                      max-height="100"
                      max-width="100"
                      :src="mdModel.image_url"
                    />
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions class="py-0">
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="toggleModal()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    isVisibleDialog: Boolean,
    toggleModal: {
      type: Function,
      default() {
        return void 0;
      },
    },
    vehicle: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      tab: 0,
      items: [
        { tab: this.$t("view_make"), content: "view-make" },
        { tab: this.$t("view_model"), content: "view-model" },
      ],
    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoadingMdMakes: "mdMakes/isLoadingMdMakes",
      mdMake: "mdMakes/getMdMakeById",
      isLoadingMdModels: "mdModels/isLoadingMdModels",
      mdModel: "mdModels/getMdModelById",
    }),
  },

  watch: {
    isVisibleDialog: {
      handler: async function (value) {
        if (!value) {
          this.tab = 0;
        }
      },
    },
  },
  async mounted() {
    await this.$store.dispatch("mdMakes/show", { id: this.vehicle.md_make_id });
    await this.$store.dispatch("mdModels/show", {
      id: this.vehicle.md_model_id,
    });
  },
  methods: {
    async tabChanged() {},
  },
};
</script>
