import { render, staticRenderFns } from "./dataTableScroll.vue?vue&type=template&id=cf538ec8&scoped=true"
import script from "./dataTableScroll.vue?vue&type=script&lang=js"
export * from "./dataTableScroll.vue?vue&type=script&lang=js"
import style0 from "./dataTableScroll.vue?vue&type=style&index=0&id=cf538ec8&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf538ec8",
  null
  
)

export default component.exports