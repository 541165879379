import service from "../services/users-service";

const state = {
  isLoadingUsers: false,
  listUsers: [],
  user: {},
  meta: {},
  editedUser: {},
  isVisibleEditDialog: false,
};

const mutations = {
  SET_USERS(state, listUsers) {
    state.listUsers = listUsers;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  ADD_USER(state, user) {
    state.listUsers.push(user);
  },
  IS_VISIBLE_EDIT_DIALOG(state, value) {
    state.isVisibleEditDialog = value;
  },
  EDITED_USER(state, user) {
    state.editedUser = user;
  },
  DELETE_USER(state, user) {
    state.listUsers.splice(
      state.listUsers.map((o) => o.id).indexOf(user.id),
      1
    );
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingUsers = true;
    service.list(params).then((data) => {
      store.commit("SET_USERS", data.body.data);
      store.commit("SET_META", data.body);
      store.state.isLoadingUsers = false;
    });
  },

  add: (store, {user,filter}) => {
    return service.add(user).then((data) => {
      store.commit("ADD_USER", data.body);
      store.dispatch("list", filter);
    });
  },

  update: (store, user) => {
    return service.update(user);
  },

  destroy: (store, user) => {
    return service.destroy(user).then(() => {
      store.commit("DELETE_USER", user);
    });
  },
};

const getters = {
  getUsersList(state) {
    return state.listUsers;
  },
  getUsersMeta(state) {
    return state.meta;
  },

  getIsLoadingUsers(state){
    return state.isLoadingUsers;
  }
};

const users = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default users;
