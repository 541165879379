import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_BASEURL;

async function get(URL, params) {
  return axios.get(`${base_url}${URL}`, { params }).then((response) => {
    return response.data;
  });
}

async function post(URL, params, config) {
  return axios.post(`${base_url}${URL}`, params, config).then((response) => {
    return response.data;
  });
}

async function update(URL, params, config) {
  return axios.patch(`${base_url}${URL}`, params, config).then((response) => {
    return response.data;
  });
}

async function put(URL, params, config) {
  return axios.put(`${base_url}${URL}`, params, config).then((response) => {
    return response.data;
  });
}

async function destroy(URL, params) {
  return (
    axios
      // .delete_method(`${base_url}${URL}?id=${params.id}`, { params })
      .delete_method(`${base_url}${URL}`, { params })
      .then((response) => {
        return response.data.body;
      })
  );
}

export default {
  get,
  post,
  update,
  put,
  destroy,
};
