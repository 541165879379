import service from "../services/telematic-details-service";

// Call APIs from Python
import serviceADX from "@/helpers/service";

const state = {
  isLoadingTelematicDetails: false,
  listTelematicDetails: [],
  isLoadingCommandsList: false,
  listCommands: [],
  telematicJSON: {},
  telematicDetail: {},
  meta: {},
  commandsMeta: {},
  editedDevice: {},
  isVisibleEditDialog: false,
  headersList: [],
  telematicChart: {},
};

const mutations = {
  SET_TELEMATICDETAILS(state, listTelematicDetails) {
    state.listTelematicDetails = listTelematicDetails;
  },

  CLEAR_TELEMATICDETAILS(state) {
    state.listTelematicDetails = [];
  },

  SET_COMMANDSLIST(state, listCommands) {
    state.listCommands = listCommands;
  },
  SET_TELEMATICJSON(state, showMsgDetail) {
    state.telematicJSON = showMsgDetail;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_COMMANDS_META(state, data) {
    if (data.meta) {
      state.commandsMeta.page = data.meta.current_page;
      state.commandsMeta.itemsPerPage = data.meta.per_page;
      state.commandsMeta.lastPage = data.meta.last_page;
      state.commandsMeta.totalItems = data.meta.total;
    }
  },
  ADD_TELEMATICDETAIL(state, telematicDetail) {
    state.listTelematicDetails.push(telematicDetail);
  },
  IS_VISIBLE_EDIT_DIALOG(state, value) {
    state.isVisibleEditDialog = value;
  },
  EDITED_TELEMATICDETAIL(state, telematicDetail) {
    state.editedTelematicDetails = telematicDetail;
  },
  UPDATE_TELEMATICDETAIL(state, telematicDetail) {
    const item = state.listTelematicDetails.find(
      (item) => item.id === telematicDetail.id
    );
    Object.assign(item, telematicDetail);
  },
  DELETE_TELEMATICDETAIL(state, telematicDetail) {
    state.listTelematicDetails.splice(
      state.listTelematicDetails.map((o) => o.id).indexOf(telematicDetail.id),
      1
    );
  },

  SET_HEADERS(state, headers) {
    state.headersList = headers;
  },
  SET_TELEMATIC_CHART(state, payload) {
    state.telematicChart = payload;
  },
};

const actions = {
  telematicHeaderInfo: (store, params) => {
    return serviceADX
      .post("vehicle/telematics-detail-header", params)
      .then((data) => {
        return data.body;
      });
  },
  overview: (store, params) => {
    return serviceADX
      .post("vehicle/telematics-detail-overview", params)
      .then((data) => {
        return data.body;
      });
  },

  list: (store, params) => {
    store.state.isLoadingTelematicDetails = true;
    service.list(params).then((data) => {
      store.commit("SET_TELEMATICDETAILS", data.body.data);
      store.commit("SET_META", data.body);
      store.state.isLoadingTelematicDetails = false;
    });
  },
  OEMlist: (store, params) => {
    store.state.isLoadingTelematicDetails = true;
    service.OEMlist(params).then((data) => {
      store.commit("SET_TELEMATICDETAILS", data.body.data);
      store.commit("SET_META", data.body);
      store.state.isLoadingTelematicDetails = false;
    });
  },

  commandslist: (store, params) => {
    store.state.isLoadingCommandsList = true;
    service.commandsList(params.imei, params).then((data) => {
      store.commit("SET_COMMANDSLIST", data.body.data);
      store.commit("SET_COMMANDS_META", data.body);
      store.state.isLoadingCommandsList = false;
    });
  },

  show: (store, params) => {
    service.show(params).then((data) => {
      store.commit("SET_TELEMATICJSON", data.body);
    });
  },

  add: (store, telematicDetail) => {
    return service.add(telematicDetail).then((data) => {
      store.commit("ADD_TELEMATICDETAIL", data.body);
    });
  },

  update: (store, telematicDetail) => {
    return service.update(telematicDetail).then((telematicDetail) => {
      store.commit("UPDATE_TELEMATICDETAIL", telematicDetail.body);
    });
  },

  destroy: (store, telematicDetail) => {
    return service.destroy(telematicDetail).then(() => {
      store.commit("DELETE_TELEMATICDETAIL", telematicDetail);
    });
  },

  headersList: (store, { tenant_id }) => {
    return service.getHeadersList(tenant_id).then((data) => {
      store.commit("SET_HEADERS", data.body.headers);
    });
  },
  setTelematicCharts(store, payload) {
    store.state.isLoadingTelematicDetails = true;
    return service.vehicleTelematicDashboard(payload).then((data) => {
      store.commit("SET_TELEMATIC_CHART", data.body.data);
      store.state.isLoadingTelematicDetails = false;
      return data.body.data;
    });
  },
  setLoading(store, val) {
    store.state.isLoadingTelematicDetails = val;
  }
};

const getters = {
  getTelematicDetailsList(state) {
    return state.listTelematicDetails;
  },
  getTelematicJSON(state) {
    return state.telematicJSON;
  },
  getTelematicDetailsMeta(state) {
    return state.meta;
  },
  getIsLoadingTelematicDetails(state) {
    return state.isLoadingTelematicDetails;
  },
  customerLists() {
    return state.listTelematicDetails.filter(
      (customer) => customer.test_customer == false
    );
  },
  testCustomersList(state) {
    return state.listTelematicDetails.filter(
      (customer) => customer.test_customer == true
    );
  },

  getCommandsList(state) {
    return state.listCommands;
  },
  getCommandsMeta(state) {
    return state.commandsMeta;
  },
  getIsLoadingCommandListing(state) {
    return state.isLoadingCommandsList;
  },

  getHeadersList(state) {
    return state.headersList;
  },
  getTelematicsChart(state) {
    return state.telematicChart;
  },
};

const telematicDetails = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default telematicDetails;
