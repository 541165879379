import axios from "@/helpers/axios";
const adx_url = process.env.VUE_APP_BASEURL;

async function list(params) {
  return axios.get(`${adx_url}roles-and-permissions/roles`, { params }).then((response) => {
    return response.data;
  });
}

async function show(role) {
  return axios.get(`${adx_url}roles-and-permissions/roles/${role.id}`).then((response) => {
    return response.data;
  });
}

async function add(role) {
  return axios.post(`${adx_url}roles-and-permissions/roles`, role).then((response) => {
    return response.data;
  });
}

async function update(role) {
  return axios.put(`${adx_url}roles-and-permissions/roles/${role.id}`, role).then((response) => {
    return response.data.body;
  });
}

async function destroy(role) {
  return axios.delete_method(`${adx_url}roles-and-permissions/roles/${role.id}`, role).then((response) => {
    return response.data.body;
  });
}

export default {
  list,
  show,
  add,
  update,
  destroy
};
