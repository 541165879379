import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios.get(`${base_url}vehicles`, { params }).then((response) => {
    return response.data;
  });
}

async function show(vehicleId) {
  return axios.get(`${base_url}vehicles/${vehicleId}`).then((response) => {
    return response.data;
  });
}

async function add(vehicle) {
  return axios.post(`${base_url}vehicles`, vehicle).then((response) => {
    return response.data;
  });
}

async function update(vehicle) {
  return axios
    .put(`${base_url}vehicles/${vehicle.id}`, vehicle)
    .then((response) => {
      return response.data;
    });
}

async function destroy(vehicle) {
  return axios
    .delete_method(`${base_url}vehicles/${vehicle.id}`)
    .then((response) => {
      return response.data;
    });
}

async function assignCustomer(vehicle) {
  return axios
    .post(`${base_url}vehicles/assign-customer/${vehicle.id}`, vehicle)
    .then((response) => {
      return response.data;
    });
}

async function bulkAssignToBusiness(vehicles) {
  return axios
    .post(`${base_url}vehicles/bulk-assignment-to-business`, vehicles)
    .then((response) => {
      return response.data;
    });
}

//
async function thirdPartiesList(params) {
  return axios
    .get(`${base_url}third-parties-list`, { params })
    .then((response) => {
      return response.data;
    });
}

export default {
  list,
  show,
  add,
  update,
  destroy,
  assignCustomer,
  bulkAssignToBusiness,
  thirdPartiesList,
};
