import service from "../services/tenant-configurations-service";

const state = {
  loading: false,
  meta: {},
  list: [],
  //
  tenantBased: {},
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_TENANT_BASED(state, payload) {
    state.tenantBased = payload;
  },
  ADD_TENANTS(state, payload) {
    state.tenantsList.unshift(payload);
  },
  DELETE_TENANTS(state, payload) {
    state.list.splice(state.list.map((o) => o.id).indexOf(payload.id), 1);
  },
};

const actions = {
  list: (store, params) => {
    store.state.loading = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_META", data.body);
        store.commit("SET_LIST", data.body.data);
      })
      .finally(() => (store.state.loading = false));
  },
  tenantBased: (store, params) => {
    return service.tenantBased(params).then((data) => {
      store.commit("SET_TENANT_BASED", data.body);
      return data.body;
    });
  },
  add_update: (store, params) => {
    return service.add_and_update(params).then(() => {
      // store.commit("ADD_TENANTS", data.body);
    });
  },
  destroy: (store, params) => {
    return service.destroy(params).then(() => {
      store.commit("DELETE_TENANTS", params);
    });
  },
};

const getters = {
  getIsLoading(state) {
    return state.loading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    return state.list;
  },
  //
  getTenantBased(state) {
    return state.tenantBased;
  },
};

const tenantConfigurations = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default tenantConfigurations;
