<template>
  <v-row :class="className">
    <slot name="before" />

    <template v-if="isDateShow">
      <DateFilter
        v-model="options.date"
        dense
        :cols="cols"
        :default-selected="dateSelected"
        :validate-date-limit="dateValidation"
        @key="(v) => (options.dateType = v)"
        @change="onDateChange"
      />
    </template>
    <!-- Product Dropdown -->
    <v-col
      v-if="authUser.isAdmin"
      :cols="cols"
    >
      <v-select
        v-model="options.product"
        dense
        :items="productOptions"
        :loading="tenantLoading"
        item-value="id"
        item-text="value"
        :label="$t('product')"
        @change="onProductChange"
      />
    </v-col>
    <!-- Product Dropdown -->
    <!-- When Product is OEM -->
    <!-- For OEM -->
    <v-col
      v-if="
        (options.product == 'oem' && authUser.isAdmin) ||
          authUser.user_nature == 'fleet'
      "
      :cols="cols"
    >
      <v-select
        v-model="options.tenant_id"
        dense
        :items="tenantOEM"
        :loading="tenantLoading"
        item-value="id"
        item-text="name"
        clearable
        :label="tenantName"
        @change="onTenantChange"
      />
    </v-col>
    <!-- For OEM -->
    <!-- FOR FLEET -->
    <v-col
      v-if="options.product == 'fleet' && authUser.isAdmin"
      :cols="cols"
    >
      <v-select
        v-model="selectedFleet"
        dense
        :items="tenantFLEET"
        :loading="tenantLoading"
        item-text="name"
        clearable
        return-object
        :label="$t('select_fleet')"
        @change="onFleetChange"
      />
      <!-- item-value="parent_tenant_ids" -->
    </v-col>
    <v-col
      v-if="options.oem_b2b_ids"
      :cols="cols"
    >
      <v-select
        v-model="options.tenant_id"
        dense
        :items="SelectedFleetTeant"
        :loading="tenantLoading"
        item-value="id"
        item-text="name"
        clearable
        :label="$t('select_oem')"
        @change="onTenantChange"
      />
    </v-col>
    <!-- FOR FLEET -->

    <!-- slot for further Filters -->
    <v-col
      v-if="filterNames.includes('search')"
      :cols="cols"
    >
      <v-text-field
        v-model="options.search"
        dense
        clearable
        :label="$t('chassis_no')"
        @change="onSearch"
      />
      <!-- @change="fetchData" -->
    </v-col>
    <slot />
    <!-- slot for further Filters -->
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {
    DateFilter: () => import("@/components/base/DateFilter.vue"),
  },
  props: {
    className: {
      type: String,
      default: () => {
        return "mx-0 mt-2";
      },
    },
    cols: {
      type: String,
      default: () => {
        return "2";
      },
    },

    isDateShow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    dateSelected: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    dateValidation: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    filterNames: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      callAfterRendered: false,
      selectedFleet: {},
      options: {},
      productOptions: [
        { id: "oem", value: "OEM" },
        { id: "fleet", value: "FLEET" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantsList",
    }),
    tenantOEM() {
      return this.tenantsList.filter((r) => r.product == "oem");
    },
    tenantFLEET() {
      return this.tenantsList.filter((r) => r.product == "fleet");
    },
    SelectedFleetTeant() {
      // .filter((r) => options.oem_b2b_ids.includes(r.id))'
      let pIds = this.selectedFleet.parent_tenant_ids;
      const arr = this.tenantsList.filter((r) => pIds.includes(r.id));
      return arr;
    },
    tenantName() {
      if (this.authUser.user_nature == "fleet") {
        return this.$t("select_oem");
      } else {
        return this.$t("select_tenant");
      }
    },
  },
  async created() {
    // Pre Select Product Dropdown
    if (this.authUser.isAdmin) {
      this.options.product = this.$route.query.product || "oem";
    } else if (this.authUser.user_nature == "fleet") {
      this.options.product = "fleet";
    } else {
      this.options.product = "oem";
    }

    if (this.authUser.isAdmin || this.authUser.user_nature == "fleet") {
      await this.$store.dispatch("tenantsManagement/list").then(() => {
        // For Fleet DropDown
        if (this.authUser.isAdmin && this.options.product == "fleet") {
          const qry = this.$route.query;
          this.selectedFleet = qry.fleetId
            ? this.tenantsList.find((r) => r.id == qry.fleetId)
            : this.tenantFLEET[0];
          this.options.fleetId = this.selectedFleet.id;
          this.options.oem_b2b_ids = this.selectedFleet.oem_b2b_ids;
        }
        // For Tenant and OEM Dropdown Super
        if (this.options.product == "oem") {
          const qryTid = parseInt(this.$route.query?.tenant_id) || null;
          // this.options.tenant_id = qryTid || this.tenantOEM[0]?.id;
          this.options.tenant_id = qryTid;
        }
        // For Tenant and OEM Dropdown fleet
        else if (this.options.product == "fleet") {
          const qryTid = parseInt(this.$route.query?.tenant_id) || null;

          if (this.authUser.isAdmin) {
            this.options.tenant_id = qryTid || this.SelectedFleetTeant[0]?.id;
          } else if (this.authUser.user_nature == "fleet") {
            this.options.tenant_id = qryTid || this.tenantsList[0]?.id;
          }
        }
      });
    }

    // pre select from Query params
    // this.options.search = 55;
    if (this.filterNames.includes("search")) {
      this.options.search = this.$route?.query?.search || null;
    }
    // pre select from Query params

    this.emitData();
    if (this.isDateShow) {
      this.callAfterRendered = true;
    }
  },
  async mounted() {},
  methods: {
    async onProductChange() {
      if (this.authUser.isAdmin) {
        this.clearFilter();
        this.selectedFleet = {};
        delete this.options.fleetId;
        delete this.options.oem_b2b_ids;
        delete this.options.tenant_id;
        // if (v == "oem") {
        //   this.options.tenant_id = this.tenantOEM[0]?.id || null;
        // } else if (v == "fleet") {
        //   // selected fleet dropdown
        //   this.selectedFleet = this.tenantFLEET[0] || null;
        //   this.options.fleetId = this.selectedFleet.id || null;
        //   this.options.oem_b2b_ids = this.selectedFleet.oem_b2b_ids || null;

        //   // oem dropdown
        //   this.options.tenant_id = this.SelectedFleetTeant[0]?.id || null;
        // }
      }
      // delete this.options.tenant_id;

      this.emitData();
    },
    onFleetChange(v) {
      this.clearFilter();

      if (v) {
        this.options.fleetId = v.id;
        this.options.oem_b2b_ids = v.oem_b2b_ids;
        this.options.tenant_id = v?.parent_tenant_ids[0] || null;
      } else {
        delete this.options.fleetId;
        delete this.options.oem_b2b_ids;
        this.options.tenant_id = null;
      }

      this.emitData();
    },
    onTenantChange() {
      this.clearFilter();

      this.emitData();
    },

    onDateChange(v) {
      this.options.date = v;
      if (this.callAfterRendered) {
        this.emitData();
      }
    },
    onSearch() {
      this.emitData();
    },

    emitData() {
      // for OEM
      if (!this.authUser.isAdmin && this.authUser.user_nature != "fleet") {
        this.options.tenant_id = this.authUser.tenant_id;
      }

      this.$emit("onChange", this.options);
    },

    clearFilter() {
      this.options.search = null;
    },
  },
};
</script>
<style lang="sass" scoped>
// .v-select
//   :deep .v-select__slot
//     .v-select__selections
//       .v-select__selection
//         text-transform: capitalize !important


// .v-select-list
//   :deep .v-list-item
//     .v-list-item__content
//       .v-list-item__title
//         text-transform: capitalize !important
</style>
