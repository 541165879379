<template>
  <div class="relative typography">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex"
          :class="textClip && 'textEllipsis'"
          :style="textClip ? { width: width } : {}"
          v-bind="attrs"
          v-on="on"
        >
          <div
            v-if="textShow"
            class="text"
          >
            {{ text }}
          </div>
          <v-icon
            v-if="copyContent"
            class="copy-content-icon"
            small
            @click="clickToCopy(text)"
          >
            mdi mdi-content-copy
          </v-icon>
        </div>
      </template>
      <span>{{ text }}</span>
    </v-tooltip>

    <!-- <v-text-field
      v-if="copyContent"
      ref="myText"
      class="copy-content-feild"
      :value="text"
      readonly
    /> -->
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: () => {
        return "";
      },
    },
    textClip: {
      type: Boolean,
      default() {
        return false;
      },
    },
    textShow: {
      type: Boolean,
      default() {
        return true;
      },
    },
    width: {
      type: String,
      default: () => {
        return "10rem";
      },
    },
    copyContent: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    clickToCopy(text) {
      // Create a temporary input element
      var input = document.createElement("input");

      // Set the value of the input (this is the text that will be copied)
      input.type = "text";
      input.value = text;

      // Append the input to the document (it's required for selection in some browsers)
      document.body.appendChild(input);

      // Select the text in the input
      input.select();

      // Using the Clipboard API to copy the text
      navigator.clipboard.writeText(input.value);

      // Remove the input element after copying
      document.body.removeChild(input);
    },
  },
};
</script>
<style lang="sass" scoped>
.typography
  cursor: pointer
  .textEllipsis
    .text
      overflow: hidden
      text-overflow: ellipsis
      text-wrap : nowrap

// .copy-content
//     overflow: hidden
.copy-content-icon
    // cursor: pointer
    // padding-left: 3rem
    // position: absolute
    // display: flex
    // justify-content: center
    // width: 100%
    // align-items: center
    // height: 100%
    &:hover
        color: #20a390  !important


.copy-content-feild
  background: red
</style>
