import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

function authHeader() {
  let accessToken = localStorage.getItem("access_token");

  if (accessToken) {
    return { Authorization: `Bearer ${accessToken}` };
  } else {
    return {};
  }
}

async function getUser() {
  return await axios.get(`${base_url}auth/user`, {
    headers: authHeader(),
  });
}

export default {
  authHeader,
  getUser,
};
