import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios.get(`${base_url}md-makes`, { params }).then((response) => {
    return response.data;
  });
}

async function show(mdMake) {
  return axios.get(`${base_url}md-makes/${mdMake.id}`).then((response) => {
    return response.data;
  });
}

async function add(mdMake) {
  return axios.post(`${base_url}md-makes`, mdMake).then((response) => {
    return response.data;
  });
}

async function update(mdMake) {
  return axios.put(`${base_url}md-makes/${mdMake.id}`, mdMake).then((response) => {
    return response.data.body;
  });
}

async function destroy(mdMake) {
  return axios.delete_method(`${base_url}md-makes/${mdMake.id}`, mdMake).then((response) => {
    return response.data.body;
  });
}

export default {
  list,
  show,
  add,
  update,
  destroy
};
