import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios.get(`${base_url}users`, { params }).then((response) => {
    return response.data;
  });
}

async function show(user) {
  return axios.get(`${base_url}users/${user.id}`).then((response) => {
    return response.data;
  });
}

async function add(user) {
  return axios.post(`${base_url}users`, user).then((response) => {
    return response.data;
  });
}

async function update(user) {
  return axios.put(`${base_url}users/${user.id}`, user).then((response) => {
    return response.data;
  });
}

async function destroy(user) {
  return axios.delete_method(`${base_url}users/${user.id}`).then((response) => {
    return response.data;
  });
}

export default {
  list,
  show,
  add,
  update,
  destroy,
};
