import Vue from "vue";

import moment from "moment";

Vue.filter("formatDate", function (value, format) {
  if (!value) return "";
  else if (value == "N/A") return "N/A";
  // return moment("2024-06-07 12:26:49 AM").format("DD-MM-YY");
  // return moment(value).format(format || "DD-MMM-YYYY HH:mm:ss");
  return moment(value).format(format || "DD-MM-YYYY hh:mm:ss A");
});

Vue.filter("currencyFormat", function (value) {
  const cur = localStorage.getItem("selectedCurrency");
  const sym = cur;
  return value.replace(/Currency/g, sym);
});
