import service from "../services/motor-types-service";

const state = {
  motorTypesList: [],
};

const mutations = {
  SET_MOTOR_TYPES(state, motorTypes) {
    state.motorTypesList = motorTypes;
  },
};

const actions = {
  list: (store, params) => {
    return service.list(params).then((data) => {
      store.commit("SET_MOTOR_TYPES", data.body.data);
    });
  },
};

const getters = {
  motorTypesList(state) {
    return state.motorTypesList;
  },
};

const motorTypes = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default motorTypes;
