import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function getFlespiCommands(params) {
  return axios.get(`${base_url}flespi-commands`, {params}).then((response) => {
    return response.data;
  });
}
async function setFlespiCommands(params) {
  return axios.post(`${base_url}flespi-commands`, params).then((response) => {
    return response.data;
  });
}


export default {
  getFlespiCommands,
  setFlespiCommands
};
