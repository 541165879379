import axios from "axios";
import router from "@/router";
import service from "@/store/services/auth-service";

export default {
  checkAuth: (store) => {
    var token =
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== undefined
        ? localStorage.getItem("access_token")
        : null;

    if (token) {
      store.commit("IS_AUTHENTICATED", true);
      store.commit("ACCESS_TOKEN", token);
    } else {
      store.commit("IS_AUTHENTICATED", false);
      store.commit("ACCESS_TOKEN", "");
    }
  },

  login: (store, credential) => {
    return new Promise((resolve, reject) => {
      axios
        // .post(process.env.VUE_APP_API_BASEURL + "login", credential)
        .post(process.env.VUE_APP_BASEURL + "orko-auth/login", credential, {
          headers: {
            Authorization: null,
          },
        })
        .then(async (response) => {
          store.commit("IS_AUTHENTICATED", true);
          store.commit("ACCESS_TOKEN", response.data.body.token);
          await store.dispatch("refreshUser");

          // store.commit("SET_USER", {
          //   ...response.data.body.user,
          //   permissions: response.data.body.permissions,
          // });

          router.go(0);
        })
        .catch(function (error) {
          reject(error.response?.data?.message);
        });
    });
  },

  loginWithAzure: () => {
    // VUE_APP_SINGLE_SIGN_ON
    return new Promise(() => {
      axios
        .get(process.env.VUE_APP_API_BASEURL + "login-with-azure")
        .then((res) => {
          if (res.status == 200) {
            window.location.href = res.data.body.redirectUrl;
          }
        });
    });
  },

  verifySSO: (store, token) => {
    // VUE_APP_SINGLE_SIGN_ON
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_API_BASEURL + "verify-azure-login-token", {
          headers,
        })
        .then(async (response) => {
          store.commit("IS_AUTHENTICATED", true);
          store.commit("ACCESS_TOKEN", response.data.body.token);
          store.commit("SET_USER", {
            ...response.data.body.user,
            permissions: response.data.body.permissions,
          });

          router.go(0);
        })
        .catch(function (error) {
          reject(error.response?.data?.message);
        });
    });
  },

  logout: (store) => {
    store.commit("IS_AUTHENTICATED", false);
    store.commit("ACCESS_TOKEN", "");
    localStorage.removeItem("redirectURL");
    localStorage.removeItem("user");
    localStorage.removeItem("tenant-list");
    return router.push({ name: "login" });
  },

  refreshUser(store) {
    return service.getUser().then((response) => {
      store.commit("SET_USER", {
        ...response.data.body.user,
        permissions: response.data.body.permissions,
      });
    });
  },
};
