import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

const headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append(
  "Authorization",
  "Bearer 72233e556bddf0767ba73ce0f5ff4debe7c33d6fce6e7104c46800a8a96a9109b53067a217361ca7f00b5232530caa835f597314dd713dc80d602b55f775d4dee304f8fa42fafa71d88763f07fed0d91cb115e979c21972516384b773a9db8421a8d59b97f0c956876684ab891d78f9676e8d6d217829f0ab3583fe22698811b"
);

async function setRoutePlan(params) {
  const payload = JSON.stringify(params);
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: payload,
    redirect: "follow",
  };
  return fetch(
    "https://preprod-api.truckistan.pk/api/customer-route-planning",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      const res = JSON.parse(result);
      return res;
    })
    .catch((error) => {
      return error;
    });
}

async function planOutput(params) {
  const payload = JSON.stringify(params);
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: payload,
    redirect: "follow",
  };

  return fetch(
    "https://preprod-api.truckistan.pk/api/customer-model-output",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      const res = JSON.parse(result);
      return res;
    })
    .catch((error) => {
      return error;
    });
}

async function routeLocations(params) {
  return axios
    .get(`${base_url}route-optimization-locations`, { params })
    .then((response) => {
      return response.data;
    });
}
async function addLocation(params) {
  return axios
    .post(`${base_url}route-optimization-locations`, params)
    .then((response) => {
      return response.data;
    });
}

export default {
  setRoutePlan,
  planOutput,
  routeLocations,
  addLocation,
};
