import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function vehicle_aggregated(params) {
  return axios.get(`${base_url}vehicle-aggregated-data`, { params }).then((response) => {
    return response.data;
  });
}

async function rider_aggregated(params) {
  return axios.get(`${base_url}rider-aggregated-data?`, { params }).then((response) => {
    return response.data;
  });
}

export default {
  vehicle_aggregated,
  rider_aggregated
};
