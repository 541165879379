import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios.get(`${base_url}md-models`, { params }).then((response) => {
    return response.data;
  });
}

async function show(mdModel) {
  return axios.get(`${base_url}md-models/${mdModel.id}`).then((response) => {
    return response.data;
  });
}

async function add(mdModel) {
  return axios
    .post(`${base_url}md-models`, mdModel, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    });
}

async function update(mdModel) {
  return axios
    .post(
      `${base_url}md-models/${mdModel.id}`,
      { ...mdModel, _method: "put" },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      return response.data.body;
    });
}

async function destroy(mdModel) {
  return axios
    .delete_method(`${base_url}md-models/${mdModel.id}`, mdModel)
    .then((response) => {
      return response.data.body;
    });
}

export default {
  list,
  show,
  add,
  update,
  destroy,
};
