import axios from "@/helpers/axios";

// Call APIs from Python
import service from "@/helpers/service";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios.get(`${base_url}routes`, { params }).then((response) => {
    return response.data;
  });
}

async function add(params) {
  return axios.post(`${base_url}routes`, params).then((response) => {
    return response.data;
  });
}

async function update(params) {
  return axios
    .put(`${base_url}routes/${params.route_id}`, params)
    .then((response) => {
      return response.data;
    });
}

async function getOne(params) {
  return axios
    .get(`${base_url}routes/${params.id}`, { params })
    .then((response) => {
      return response.data;
    });
}

async function destroy(params) {
  return axios
    .delete_method(`${base_url}routes/${params.id}`, params)
    .then((response) => {
      return response.data.body;
    });
}

//
async function compareRoute(params) {
  return service.get("/routes/compare-route", params).then((response) => {
    return response.body;
  });

  // return axios.get(`${base_url}compare-route`, { params }).then((response) => {
  //   return response.data.body;
  // });
}

export default {
  list,
  add,
  update,
  getOne,
  destroy,
  compareRoute,
};
