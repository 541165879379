import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios
    .get(`${base_url}tenant-configurations`, { params })
    .then((response) => {
      return response.data;
    });
}
async function tenantBased(params) {
  return axios
    .get(`${base_url}tenant-based-configurations/${params.tenant_id}`)
    .then((response) => {
      return response.data;
    });
}

// Add and Update same Method

async function add_and_update(params) {
  return axios
    .post(`${base_url}tenant-configurations`, params)
    .then((response) => {
      return response.data;
    });
}

async function destroy(params) {
  return axios
    .post(`${base_url}delete-tenant-configurations/${params.id}`, params)
    .then((response) => {
      return response.data.body;
    });
}

export default {
  list,
  tenantBased,
  add_and_update,
  destroy,
};
