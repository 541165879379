import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios
    .get(`${base_url}upcoming-maintenance-vehicles-list`, { params })
    .then((response) => {
      return response.data;
    });
}

export default {
  list,
};
