import store from "@/store";
import admin from "../plugins/admin";

export default function guest({ next, router }) {
  store.dispatch("checkAuth");
  if (store.getters.isAuthenticated) {
    // check redirect URL
    const key = "redirectURL";
    const URL = localStorage.getItem(key);
    if (URL) {
      localStorage.removeItem("redirectURL");
      window.location.href = URL;
    }
    //
    else if (admin.hasAccessTo("dashboard.list")) {
      return router.push({ name: "dashboard" });
    } else {
      return router.push({ name: "vehicle_telematics" });
    }
  }

  return next();
}
