<template>
  <div class="verticle-scroll">
    <v-btn
      class="px-0 mx-0"
      text
      icon
      small
      @click="scrollLeft"
    >
      <v-icon large>
        mdi-pan-left
      </v-icon>
    </v-btn>
    <v-btn
      class="px-0 mx-0"
      text
      icon
      small
      @click="scrollRight"
    >
      <v-icon large>
        mdi-pan-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "DataTableScroll",
  props: {
    dataTableKey: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  methods: {
    scrollLeft() {
      const t = document.getElementsByClassName("v-data-table__wrapper")[
        this.dataTableKey
      ];
      t.scrollTo({ left: 0, behavior: "smooth" });
    },
    scrollRight() {
      const t = document.getElementsByClassName("v-data-table__wrapper")[
        this.dataTableKey
      ];
      // const w = t.offsetWidth;
      const sl = t.scrollLeft + 250;
      t.scrollTo({ left: sl, behavior: "smooth" });
    },
  },
};
</script>
<style lang="sass" scoped>
.verticle-scroll
  background: #F2F4F7 !important
  display: flex
  justify-content: space-between
</style>
