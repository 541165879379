import service from "../services/oemclient-service";

const state = {
  isLoadingOems: false,
  oemsList: [],
  oem: {},
  meta: {},
  editedOEM: {},
  isVisibleEditDialog: false,
};

const mutations = {
  SET_OEMS_LIST(state, oemsList) {
    state.oemsList = oemsList;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  ADD_OEM(state, oem) {
    state.oemsList.push(oem);
  },
  IS_VISIBLE_EDIT_DIALOG(state, value) {
    state.isVisibleEditDialog = value;
  },
  DELETE_OEM(state, oem) {
    state.oemsList.splice(state.oemsList.map((o) => o.id).indexOf(oem.id), 1);
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingOems = true;
    service.list(params).then((data) => {
      store.commit("SET_OEMS_LIST", data.body.data);
      store.commit("SET_META", data.body);
      store.state.isLoadingOems = false;
    });
  },

  add: (store, oem) => {
    return service.add(oem).then((data) => {
      store.commit("ADD_OEM", data.body);
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  update: (store, oem) => {
    return service.update(oem);
  },

  destroy: (store, oem) => {
    return service.destroy(oem).then(() => {
      store.commit("DELETE_OEM", oem);
    });
  },
};

const getters = {
  getOemsList(state) {
    return state.oemsList;
  },
  getOemsMeta(state) {
    return state.meta;
  },

  getIsLoadingOems(state) {
    return state.isLoadingOems;
  },
};

const oemclients = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default oemclients;
