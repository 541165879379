import service from "../services/locations-service";

const state = {
  locationsList: [],
};

const mutations = {
  SET_LOCATIONS(state, locations) {
    state.locationsList = locations;
  },
};

const actions = {
  list: (store, params) => {
    return service.list(params).then((data) => {
      store.commit("SET_LOCATIONS", data.body.data);
    });
  },
};

const getters = {
  locationsList(state) {
    return state.locationsList;
  },
};

const locations = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default locations;
