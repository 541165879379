<template>
  <v-list>
    <v-list-item>
      <v-list-item-title>
        <v-text-field
          v-model="search"
          label="Search"
          @keyup="onSearch"
        />
      </v-list-item-title>
    </v-list-item>
    <v-list-item
      v-for="(item, index) in tempHeader"
      :key="index"
      class="filter-list"
    >
      <v-list-item-title>
        <v-checkbox
          id="index"
          v-model="item.selected"
          :label="`${item.heading}`"
          hide-details
          :ripple="false"
          @change="onHeaderChange(item)"
        />
      </v-list-item-title>
    </v-list-item>
    <v-list-item class="action-btn">
      <v-list-item-action>
        <v-btn
          color="primary"
          small
          :loading="isLoading"
          @click="applySettings"
        >
          {{ $t("add_columns") }}
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "TableHeadFilters",
  props: {
    name: {
      type: String,
      default() {
        return "";
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isShow: false,
      search: "",
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      selectedItems: "tableHeaders/getDataHeaders",
      payloadHeaders: "tableHeaders/getPayloadHeaders",
      isLoading: "tableHeaders/getLoading",
    }),
    tempHeader() {
      if (this.search.length > 1) {
        let re = new RegExp(this.search, "gi");
        const arr = this.items.filter((r) => r.heading.match(re));
        return JSON.parse(JSON.stringify(arr));
      }
      return JSON.parse(JSON.stringify(this.items));
    },
  },
  methods: {
    onSearch() {
      if (this.search.length > 1) {
        let re = new RegExp(this.search, "gi");
        this.tempHeader = this.items.filter((r) => r.heading.match(re));
      }
    },

    onHeaderChange(row) {
      this.$store.commit("tableHeaders/UPDATE_DATA_HEADERS", row);
    },
    applySettings() {
      let columns = this.tempHeader.filter((r) => r.selected).map((r) => r.key);
      columns = [...new Set([...this.payloadHeaders, ...columns])];
      let params = {
        // tenant_id:
        // this.$route.query.tenant_id ?? this.authUser.tenant_id,
        // dt_name: this.$route.name,
        dt_name: "telematic_details",
        columns: columns,
      };

      if (this.authUser.isAdmin) {
        params.tenant_id =
          this.$route.query.fleetId || this.$route.query.tenant_id;
      } else {
        params.tenant_id = this.authUser.tenant_id;
      }

      this.$store.dispatch("tableHeaders/update", params).then(() => {
        this.$store.dispatch("alerts/success", this.$t("updated_successfully"));
        this.$emit("addColumns");
        this.isShow = false;
      });
    },
  },
};
</script>
<style lang="sass" scoped>

.v-menu__content
  .v-list
    max-height: 300px
  .action-btn
    border-top: 1px solid lightgray
    background:  white
    position: sticky
    bottom: 0
</style>
