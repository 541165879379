import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios.get(`${base_url}data-sims`, { params }).then((response) => {
    return response.data;
  });
}

async function show(dataSim) {
  return axios.get(`${base_url}data-sims/${dataSim.id}`).then((response) => {
    return response.data;
  });
}

async function add(dataSim) {
  return axios.post(`${base_url}data-sims`, dataSim).then((response) => {
    return response.data;
  });
}

async function update(dataSim) {
  return axios.put(`${base_url}data-sims/${dataSim.id}`, dataSim).then((response) => {
    return response.data.body;
  });
}

async function destroy(dataSim) {
  return axios.delete_method(`${base_url}data-sims/${dataSim.id}`, dataSim).then((response) => {
    return response.data.body;
  });
}

export default {
  list,
  show,
  add,
  update,
  destroy
};
