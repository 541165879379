import Vue from "vue";


export default {
  IS_AUTHENTICATED(state, is_auth) {
    Vue.set(state, "isAuthenticated", is_auth);
  },
  ACCESS_TOKEN(state, access_token) {
    localStorage.setItem("access_token", access_token);
    Vue.set(state, "accessToken", access_token);
  },
  SET_USER(state, user) {
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(user));
    Vue.set(state, "user", user);
  },
};
