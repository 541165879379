import service from "../services/battery-makes-service";

const state = {
  batteryMakesList: [],
  isLoadingBatteryMakesList: false,
};

const mutations = {
  SET_BATTERY_MAKES(state, batteryMakes) {
    state.batteryMakesList = batteryMakes;
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingBatteryMakesList = true;
    return service.list(params).then((data) => {
      store.commit("SET_BATTERY_MAKES", data.body.data);
      store.state.isLoadingBatteryMakesList = false;
    });
  },
};

const getters = {
  batteryMakesList(state) {
    return state.batteryMakesList;
  },
  isLoadingBatteryMakesList(state) {
    return state.isLoadingBatteryMakesList;
  },
};

const batteryMakes = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default batteryMakes;
