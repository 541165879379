import service from "../services/oem-b2b-service";

const state = {
  isLoading: false,
  oemList: [],
  meta: {},
  //
  listPayload: {},
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_OEM_LIST(state, payload) {
    state.oemList = payload;
  },
  ADD_OEM_LIST(state, payload) {
    state.oemList.push(payload);
  },
  DELETE_OEM(state, oem) {
    state.oemList.splice(state.oemList.map((o) => o.id).indexOf(oem.id), 1);
  },
};

const actions = {
  list: (store, params) => {
    const p = JSON.stringify(store.state.listPayload);
    const pp = JSON.stringify(params);
    if (pp !== p || store.state.oemList.length == 0) {
      store.state.isLoading = true;
      return service.list(params).then((data) => {
        store.state.listPayload = params; // set params in vue to remove unwanted Call
        store.commit("SET_OEM_LIST", data.body.data);
        store.commit("SET_META", data.body);
        store.state.isLoading = false;
      });
    }
  },
  add: (store, params) => {
    return service.add(params).then((data) => {
      store.commit("ADD_OEM_LIST", data.body);
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },
  update: (store, oem) => {
    return service.update(oem).then(() => {
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  destroy: (store, oem) => {
    return service.destroy(oem).then(() => {
      store.commit("DELETE_OEM", oem);
    });
  },
};

const getters = {
  getIsLoading(state) {
    return state.isLoading;
  },
  getOEMB2bMeta(state) {
    return state.meta;
  },
  getOEMB2BList(state) {
    return state.oemList;
  },
};

const oemBusiness = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default oemBusiness;
