import service from "../services/oem-customers-service";
const state = {
  isLoadingOemCustomers: false,
  listOemCustomers: [],
  oemCustomer: {},
  meta: {},
  editedDevice: {},
  isVisibleEditDialog: false,
};

const mutations = {
  SET_OEMCUSTOMERS(state, listOemCustomers) {
    state.listOemCustomers = listOemCustomers;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  ADD_OEMCUSTOMER(state, oemCustomer) {
    state.listOemCustomers.push(oemCustomer);
  },
  IS_VISIBLE_EDIT_DIALOG(state, value) {
    state.isVisibleEditDialog = value;
  },
  EDITED_OEMCUSTOMER(state, oemCustomer) {
    state.editedOemCustomers = oemCustomer;
  },
  UPDATE_OEMCUSTOMER(state, oemCustomer) {
    const item = state.listOemCustomers.find(
      (item) => item.id === oemCustomer.id
    );
    Object.assign(item, oemCustomer);
  },
  DELETE_OEMCUSTOMER(state, oemCustomer) {
    state.listOemCustomers.splice(
      state.listOemCustomers.map((o) => o.id).indexOf(oemCustomer.id),
      1
    );
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingOemCustomers = true;
    service.list(params).then((data) => {
      store.commit("SET_OEMCUSTOMERS", data.body.data);
      store.commit("SET_META", data.body);
      store.state.isLoadingOemCustomers = false;
    });
  },

  add: (store, {oemCustomer,filter}) => {
    return service.add(oemCustomer).then((data) => {
      store.commit("ADD_OEMCUSTOMER", data.body);
      // store.dispatch("list", { page: 1, itemsPerPage: 30 });
      store.dispatch("list", filter);
    });
  },

  update: (store, oemCustomer) => {
    return service.update(oemCustomer).then((oemCustomer) => {
      store.commit("UPDATE_OEMCUSTOMER", oemCustomer.body);
    });
  },

  destroy: (store, oemCustomer) => {
    return service.destroy(oemCustomer).then(() => {
      store.commit("DELETE_OEMCUSTOMER", oemCustomer);
    });
  },
};

const getters = {
  getOemCustomersList(state) {
    return state.listOemCustomers;
  },
  getOemCustomersMeta(state) {
    return state.meta;
  },
  getIsLoadingOemCustomers(state) {
    return state.isLoadingOemCustomers;
  },
  customerLists() {
    return state.listOemCustomers.filter(
      (customer) => customer.test_customer == false && customer.is_active == true
    );
  },
  testCustomersList(state) {
    return state.listOemCustomers.filter(
      (customer) => customer.test_customer == true
    );
  },
  activeTestCustomersList(state) {
    return state.listOemCustomers.filter(
      (customer) => customer.test_customer == true && customer.is_active == true
    );
  },
};

const oemCustomers = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default oemCustomers;
