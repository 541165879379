import service from "../services/battery-types-service";

const state = {
  batteryTypesList: [],
  isLoadingBatteryTypes: false,
};

const mutations = {
  SET_BATTERY_TYPES(state, batteryTypes) {
    state.batteryTypesList = batteryTypes;
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingBatteryTypes = true;
    return service.list(params).then((data) => {
      store.commit("SET_BATTERY_TYPES", data.body.data);
      store.state.isLoadingBatteryTypes = false;
    });
  },
};

const getters = {
  batteryTypesList(state) {
    return state.batteryTypesList;
  },
  isLoadingBatteryTypes(state){
    return state.isLoadingBatteryTypes;
  }
};

const batteryTypes = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default batteryTypes;
