import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {

  return axios.get(`${base_url}iot-device-models`, { params }).then((response) => {

    return response.data;
  });
}

async function show(iotDeviceModel) {
  return axios.get(`${base_url}iot-device-models/${iotDeviceModel.id}`).then((response) => {
    return response.data;
  });
}

async function add(iotDeviceModel) {
  return axios.post(`${base_url}iot-device-models`, iotDeviceModel).then((response) => {
    return response.data;
  });
}

async function update(iotDeviceModel) {
  return axios.put(`${base_url}iot-device-models/${iotDeviceModel.id}`, iotDeviceModel).then((response) => {
    return response.data;
  });
}

async function destroy(iotDeviceModel) {
  return axios.delete_method(`${base_url}iot-device-models/${iotDeviceModel.id}`).then((response) => {
    return response.data;
  });
}

export default {
  list,
  show,
  add,
  update,
  destroy,
};
