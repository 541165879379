import store from "@/store";

export default function auth({ next, router }) {
  // set redirect after login, when user enter URL and go to login
  localStorage.setItem("redirectURL", window.location.href);
  //
  store.dispatch("checkAuth");
  if (!store.getters.isAuthenticated) {
    return router.push({ name: "login" });
  }

  return next();
}
