import service from "@/helpers/service";

const state = {
  loading: false,
  // meta: {},
  singleTripPrompt: [],
  singleTripList: [],
  //
  tripDetailPrompt: [],
  tripDetailList: [],
};

const mutations = {
  SET_SINGLE_TRIP_PROMPT(state, payload) {
    state.singleTripPrompt = payload;
  },
  SET_SINGLE_TRIP_LIST(state, payload) {
    state.singleTripList.push(payload);
  },
  SET_TRIP_DETAIL_PROMPT(state, payload) {
    state.tripDetailPrompt = payload;
  },
  SET_TRIP_DETAIL_LIST(state, payload) {
    state.tripDetailList.push(payload);
  },
};

const actions = {
  singleTripPrompt: (store, params) => {
    store.commit("SET_SINGLE_TRIP_PROMPT", params);
  },
  singleTripList: (store, params) => {
    store.state.loading = true;
    const sendingNotes = {
      type: "sent",
      msg: params.promptLabel,
    };
    store.commit("SET_SINGLE_TRIP_LIST", sendingNotes);
    return service
      .get("ai/trip-summary", params)
      .then((data) => {
        const receivingNotes = {
          type: "received",
          msg: data.body.data,
        };
        store.commit("SET_SINGLE_TRIP_LIST", receivingNotes);
        return data.body;
      })
      .finally(() => (store.state.loading = false));
  },
  tripDetailPrompt: (store, params) => {
    store.commit("SET_TRIP_DETAIL_PROMPT", params);
  },
  tripDetailList: (store, params) => {
    store.state.loading = true;
    const sendingNotes = {
      type: "sent",
      msg: params.promptLabel,
    };
    store.commit("SET_TRIP_DETAIL_LIST", sendingNotes);
    return service
      .get("ai/trip-summary-detail", params)
      .then((data) => {
        const receivingNotes = {
          type: "received",
          msg: data.body.data,
        };
        store.commit("SET_TRIP_DETAIL_LIST", receivingNotes);
        return data.body;
      })
      .finally(() => (store.state.loading = false));
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getSingleTripPrompt(state) {
    return state.singleTripPrompt;
  },
  getSingleTripList(state) {
    return state.singleTripList;
  },
  getTripDetailPrompt(state) {
    return state.tripDetailPrompt;
  },
  getTripDetailList(state) {
    return state.tripDetailList;
  },
};

const generativeAI = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default generativeAI;
